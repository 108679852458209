import '@fontsource/karla/400.css';
// import '@fontsource/raleway';
import '@fontsource/fira-code/400.css';
import '@fontsource/shrikhand/400.css';

import './src/styles/global.css';
import CustomLayout from './wrapPageElement';

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = CustomLayout;
