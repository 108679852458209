export const routes: string[] = [
  '/',
  '/arSlanted/',
  '/arSlanted2/',
  '/arSlanted3/',
  '/arSlanted4/',
  '/arSlanted5/',
  '/arSlanted6/',
  '/arSlanted7/',
  '/arSlanted8/',
  '/arSlanted9/',
  '/arSlanted10/',
  '/arSlanted11/',
  '/artworkGenerator/',
  '/artworkGenerator2/',
  '/artworkGenerator3/',
  '/artworkGenerator4/',
  '/artworkGenerator5/',
  '/artworkGenerator6/',
  '/artworkGenerator7/',
  '/mateLights/',
  '/mateLights2/',
  '/mateLights3/',
  '/mateLights4/',
  '/mateLights5/',
  '/mateLights6/',
  '/mateLights7/',
  '/mateLights8/',
  '/mateLights9/',
  '/end/',
];
